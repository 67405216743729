

import UMUM from '../../library/umum'


export default {
  data() {
    return {
      

      form : {
        id : '',
        uraian : '',
        jeniskategorilokasi : '',
        lat : '',
        lng : '',
        rad : 100,
        keterangan : '',
        file : null,
        file_old : '',
        unit_kerja : '',
        status : 0,
        verify_at : '',
        verifikator_nama : '',
        verifikator_gelar_depan: '',
        verifikator_gelar_belakang : '',
      },
     
      list_data : [],


      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_maps_tambah : false,
      mdl_img : false,

      UMUM : UMUM,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_UsulanLokasi + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              unit_kerja : this.form.unit_kerja
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      var formData = new FormData();

      formData.append('id', this.form.id);
      formData.append('uraian', this.form.uraian);
      formData.append('jeniskategorilokasi', this.form.jeniskategorilokasi);
      formData.append('lat', this.form.lat);
      formData.append('lng', this.form.lng);
      formData.append('rad', this.form.rad);
      formData.append('keterangan', this.form.keterangan);
      formData.append('file', this.form.file);
      formData.append('file_old', this.form.file_old);
      formData.append('unit_kerja', this.form.unit_kerja);
     

      fetch(this.$store.state.url.URL_UsulanLokasi + "Add", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
      // console.log(this.form.jeniskategorilokasi)
    },


    editData : function(){
      var formData = new FormData();

      formData.append('id', this.form.id);
      formData.append('uraian', this.form.uraian);
      formData.append('jeniskategorilokasi', this.form.jeniskategorilokasi);
      formData.append('lat', this.form.lat);
      formData.append('lng', this.form.lng);
      formData.append('rad', this.form.rad);
      formData.append('keterangan', this.form.keterangan);
      formData.append('file', this.form.file);
      formData.append('file_old', this.form.file_old);
      formData.append('unit_kerja', this.form.unit_kerja);


      fetch(this.$store.state.url.URL_UsulanLokasi + "editData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_UsulanLokasi + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id, file : this.form.file})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){
        console.log(data)
        this.form.id = data.id;
        this.form.uraian = data.uraian;
        this.form.jeniskategorilokasi = data.jeniskategorilokasi;
        this.form.lat = data.lat;
        this.form.lng = data.lng;
        this.form.rad = data.rad;
        this.form.keterangan = data.keterangan;
        this.form.file = data.file;
        this.form.file_old = data.file;
        this.form.unit_kerja = data.unit_kerja;
        this.form.status = data.status;
        this.form.verifikator_nama = data.verifikator_nama;
        this.form.verifikator_gelar_depan = data.verifikator_gelar_depan;
        this.form.verifikator_gelar_belakang = data.verifikator_gelar_belakang;

        this.form.verify_at = data.verify_at;

    },

    // ====================================== TAMBAHAN ====================================
      clearCoordinate (){
        this.form.lat = ''; this.form.lng = '';
      },
      getCoordinate(){
        this.$getLocation()
        .then(coordinates => {
          this.form.lat = coordinates.lat;
          this.form.lng = coordinates.lng;
        });
      },
      updateCoordinates(location){
        this.form.lat = location.latLng.lat();
        this.form.lng = location.latLng.lng();
      },
      parseFloatku(data){
        return parseFloat(data)
      },


    // ====================================== TAMBAHAN ====================================

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : true
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : false
            }
          }

        }


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    this.$getLocation()
    .then(coordinates => {
      this.form.lat = coordinates.lat;
      this.form.lng = coordinates.lng;

      this.$store.state.coordinat.lat = coordinates.lat;
      this.$store.state.coordinat.lng = coordinates.lng;
    });

    this.$store.commit("listJeniskategorilokasi");
    this.$store.commit("getStorage");

    this.form.unit_kerja = this.$store.state.unit_kerja

      this.getView();




  },
}
